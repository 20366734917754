export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  getButtonState() {
    const { eatingMeatOften, likeBetter2, hiccups, childrenUpTo18, alcoholConsumption } =
      this.formValues

    this.showButton = !!(
      eatingMeatOften &&
      likeBetter2 &&
      hiccups &&
      (childrenUpTo18 || alcoholConsumption)
    )
  },
})
