import { kgToLbs, lbsToKg } from "../dataStore"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    if (this.store.isMetric) {
      this.formValues.targetWeightKg =
        data?.formValues?.targetWeightKg || this.store.values.targetWeightKg
    } else {
      this.formValues.targetWeightLbs =
        data?.formValues?.targetWeightLbs || this.store.values.targetWeightLbs
    }
  },

  processFormValues() {
    const { targetWeightKg, targetWeightLbs } = this.formValues

    const targetWeightMetric = targetWeightKg || this.store.formattedIdealWeight().replace("kg", "")
    const targetWeightImperial =
      targetWeightLbs || this.store.formattedIdealWeight().replace("lbs", "")

    if (this.store.isMetric) {
      const convertedTargetWeightLbs = Math.round(kgToLbs(targetWeightMetric))

      return {
        targetWeightLbs: convertedTargetWeightLbs,
        targetWeightKg: Number(targetWeightMetric),
      }
    } else {
      const convertedTargetWeightKg = Math.round(lbsToKg(targetWeightImperial))

      return {
        targetWeightKg: convertedTargetWeightKg,
        targetWeightLbs: Number(targetWeightImperial),
      }
    }
  },

  getButtonState() {
    const { targetWeightKg, weightLbs } = this.formValues
    const weight = this.store.isMetric ? targetWeightKg : weightLbs

    this.showButton = !!(weight || weight === 0)
  },
})
