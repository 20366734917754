export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    this.formValues.age = data?.formValues?.age || this.store.values.age
  },

  getButtonState() {
    const { age } = this.formValues

    console.log(this.formValues)

    this.showButton = !!(age || age === 0)
  },
})
