import { kgToLbs, lbsToKg, cmToFtIn, ftInToCm } from "../dataStore"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    this.formValues.age = data?.formValues?.age || this.store.values.age
    this.formValues.firstName = data?.formValues?.firstName || this.store.values.firstName

    if (this.store.isMetric) {
      this.formValues.heightCm = data?.formValues?.heightCm || this.store.values.heightCm
      this.formValues.weightKg = data?.formValues?.weightKg || this.store.values.weightKg
    } else {
      this.formValues.heightFt = data?.formValues?.heightFt || this.store.values.heightFt
      this.formValues.heightIn = data?.formValues?.heightIn || this.store.values.heightIn
      this.formValues.weightLbs = data?.formValues?.weightLbs || this.store.values.weightLbs
    }
  },

  getConvertedWeights() {
    if (!this.store.isMetric) {
      if (!this.formValues.weightKg) return
      const { weightKg } = this.formValues

      const weightLbs = Math.round(kgToLbs(weightKg))

      return weightLbs
    } else {
      if (!this.formValues.weightLbs) return
      const { weightLbs } = this.formValues

      const weightKg = Math.round(lbsToKg(weightLbs))

      return weightKg
    }
  },

  getConvertedHeights() {
    if (!this.store.isMetric) {
      if (!this.formValues.heightCm) return
      const { heightCm } = this.formValues

      const imperialHeight = cmToFtIn(heightCm)
      const heightFt = imperialHeight.heightFt
      const heightIn = imperialHeight.heightIn

      return { heightFt, heightIn }
    } else {
      if (!this.formValues.heightFt || !this.formValues.heightIn) return
      const { heightFt, heightIn } = this.formValues

      const heightCm = ftInToCm({ ft: heightFt, inch: heightIn })

      return heightCm
    }
  },

  convertHeight() {
    if (!this.store.isMetric) {
      const { heightFt, heightIn } = this.getConvertedHeights()
      const weightLbs = this.getConvertedWeights()

      this.formValues.heightFt = heightFt
      this.formValues.heightIn = heightIn
      this.formValues.weightLbs = weightLbs
    } else {
      const heightCm = this.getConvertedHeights()
      const weightKg = this.getConvertedWeights()

      this.formValues.heightCm = heightCm
      this.formValues.weightKg = weightKg
    }
  },

  convertWeight() {
    if (!this.store.isMetric) {
      const weightLbs = this.getConvertedWeights()
      const { heightFt, heightIn } = this.getConvertedHeights()

      this.formValues.weightLbs = weightLbs
      this.formValues.heightFt = heightFt
      this.formValues.heightIn = heightIn
    } else {
      const weightKg = this.getConvertedWeights()
      const heightCm = this.getConvertedHeights()

      this.formValues.weightKg = weightKg
      this.formValues.heightCm = heightCm
    }
  },

  processFormValues() {
    const { age, firstName, heightCm, heightFt, heightIn, weightKg, weightLbs } = this.formValues

    if (this.store.isMetric) {
      return {
        heightCm,
        weightKg,
        age,
        firstName,
        isMetric: this.store.isMetric,
      }
    } else {
      return {
        age,
        firstName,
        heightFt,
        heightIn,
        weightLbs,
        isMetric: this.store.isMetric,
      }
    }
  },

  getButtonState() {
    const { age, heightCm, heightFt, heightIn, weightKg, weightLbs, firstName } = this.formValues
    const hiddenInputCm = document.getElementById("heightCm")
    const hiddenInputFt = document.getElementById("heightFt")
    const hiddenInputIn = document.getElementById("heightIn")

    if (this.store.isMetric) {
      hiddenInputFt.removeAttribute("required")
      hiddenInputIn.removeAttribute("required")
    } else {
      hiddenInputCm.removeAttribute("required")
    }

    if (this.store.isMetric) {
      this.showButton = !!(heightCm || heightCm === 0) && !!(weightKg || weightKg === 0)
    } else if (this.store.isImperial) {
      this.showButton =
        !!(heightFt || heightFt === 0) &&
        (heightIn || heightIn === 0) &&
        !!(weightLbs || weightLbs === 0)
    }
    this.showButton = !!(age || age === 0) && !!firstName
  },
})
