import { kgToLbs, lbsToKg } from "../dataStore"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    if (this.store.isMetric) {
      this.formValues.weightKg = data?.formValues?.weightKg || this.store.values.weightKg
    } else {
      this.formValues.weightLbs = data?.formValues?.weightLbs || this.store.values.weightLbs
    }
  },

  getConvertedWeights() {
    if (!this.store.isMetric) {
      if (!this.formValues.weightKg) return
      const { weightKg } = this.formValues

      const weightLbs = Math.round(kgToLbs(weightKg))

      return weightLbs
    } else {
      if (!this.formValues.weightLbs) return
      const { weightLbs } = this.formValues

      const weightKg = Math.round(lbsToKg(weightLbs))

      return weightKg
    }
  },

  convertWeight() {
    if (!this.store.isMetric) {
      const weightLbs = this.getConvertedWeights()

      this.formValues.weightLbs = weightLbs
    } else {
      const weightKg = this.getConvertedWeights()

      this.formValues.weightKg = weightKg
    }
  },

  processFormValues() {
    const { weightKg, weightLbs } = this.formValues

    if (this.store.isMetric) {
      const convertedWeightLbs = Math.round(kgToLbs(weightKg))

      return { weightLbs: convertedWeightLbs, weightKg, isMetric: this.store.isMetric }
    } else {
      const convertedWeightKg = Math.round(lbsToKg(weightLbs))

      return { weightKg: convertedWeightKg, weightLbs, isMetric: this.store.isMetric }
    }
  },

  getButtonState() {
    const weight = this.store.isMetric ? this.formValues.weightKg : this.formValues.weightLbs

    this.showButton = !!(weight || weight === 0)
  },
})
