export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    this.formValues.firstName = data?.formValues?.firstName || this.store.values.firstName
  },

  getButtonState() {
    this.showButton = !!(this.formValues.firstName && this.formValues.firstName.length > 0)
  },
})
