export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  getButtonState() {
    const { wantToLoseWeight, milkAllergy, privacyAgreement, weightLossReceive, email } =
      this.formValues
    const notAgreed = privacyAgreement == "No"
    const agreementErrorMessage = "(you must agree to the terms to proceed)"

    this.errors = {
      privacyAgreement: notAgreed ? agreementErrorMessage : "",
      email: this.errors.email,
    }

    //if (notAgreed) return (this.showButton = false)
  if (notAgreed) {
    this.errors = {
      privacyAgreement: notAgreed ? agreementErrorMessage : "",
    }
    return (this.showButton = false)
  }

    return (this.showButton = !!(
      wantToLoseWeight &&
      milkAllergy &&
      privacyAgreement &&
      weightLossReceive &&
      email
    ))
  },
})
